<template>
  <rs-row>
    <rs-col>
      <rs-card title='标签'>
        <div v-for="(value,index) in tag.children" :key="index">
          <div v-if="value.children">
            <div style="background-color:#26c6da;padding:5px">
              <a href="#" style="color:#fff;font-weight:400" @click="$refs.taginfo.handle(value.value)">{{value.label}}</a>
            </div>
            <div v-for="(v,i) in value.children" :key="i" style="background-color:#eee;margin-left:20px">
              <div v-if="value.children">
                <a href="#" style="color:#333;margin:5px" @click="$refs.taginfo.handle(v.value,v.label)">{{v.label}}</a>
                <div v-for="(v1,ia) in v.children" :key="ia">
                  <a href="#" @click="$refs.taginfo.handle(v1.value)">{{v1.label}}</a>
                </div>
              </div>
              <div v-else>
                <a href="#" @click="$refs.taginfo.handle(v.value,v.label)">{{v.label}}</a>
              </div>
            </div>
          </div>
          <div v-else>
            <a href="#" @click="$refs.taginfo.handle(value.value)">{{value.label}}</a>
          </div>
        </div>
      </rs-card>
    </rs-col>
    <tag-info ref="taginfo"></tag-info>
  </rs-row>
</template>

<script>

import request from '@/utility/request'
import tagInfo from './curd/tagInfo.vue'
export default {
  components: { tagInfo },

  data () {
    return {
      tag: { children: [] }
    }
  },
  mounted () {
    request.get('/tag/allList').then(data => {
      this.tag = data.data[0]
    })
  },
  methods: {
    updateItem (type) {
      switch (type) {
        case 'staff':
          this.updateStaff()
          break
      }
    }
  }
}
</script>
