<template>
      <rs-dialog :visible.sync="visible" :title="title+'('+type+')'">
        <rs-table :data="pay" :column="{id:'id',name:'名义',money:'金额',time:'时间'}">
            <template slot-scope="scope">
                <td>无操作 {{scope.row.bid?"*":''}}</td>
            </template>
            <tr slot="count">
                <td colspan="1">
                </td>
                <td class="td-price text-right">
                <small>￥</small>
                </td>
                <td>{{payMoney}}</td>
                <td colspan="2"></td>
            </tr>
        </rs-table>
      </rs-dialog>
</template>

<script>
import request from '@/utility/request'
export default {
  data () {
    return {
      title: '标签信息',
      visible: false,
      type: '',
      pay: []
    }
  },
  methods: {
    handle (id, type) {
      this.type = type
      this.pay = []
      request.post('/pay/tagPay?id=' + id).then(function (data) {
        this.pay = data.data
      }.bind(this))
      this.visible = true
    }
  },
  computed: {
    payMoney () {
      let money = 0
      this.pay.forEach(value => {
        money += value.money
      })
      return money
    }
  }
}
</script>
